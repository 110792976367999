
import { navigate } from "gatsby";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import networkIcon from '../../../images/chain-icon';
import { networks } from '../../../web3/web3.js';
import { useVaultData } from '../../../state/vault-staking';

const statusMapping = {
    0: "Upcoming",
    1: "Live",
    2: "Live",
    3: "Ended",
    4: "Loading"
};

const typeMapping = {
    FLASH_STAKING: "Flash Staking",
    TIME_LOCK: "Time Lock",
    SIMPLE_STAKING: "Classic",
    AUTOCOMPOUND_RELOCK: "Autocompound",
}


function Vault({ name, address, imagePath, type, typeDescription, location, ...vaultInfo }) {

    const vaultData = useVaultData(address);
    const [status, setStatus] = useState("Loading");

    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setStatus(statusMapping[vaultData.status]);

    }, [vaultData, setStatus]);
    return (
        <>
            <div className="staking-component h-100">
                <Card>
                    <Card.Body>
                        <Row style={{ marginBottom: '1.25rem' }}>
                            <Col className="d-flex align-items-center col-auto">
                                <img
                                    style={{ borderRadius: '50%' }}
                                    src={'/vaults/' + imagePath}
                                    alt={`${name} vault`}
                                    width={45}
                                    height={45}
                                />
                                <div className="ms-3">
                                    <h6 className="mb-0" style={{ lineHeight: 1.2 }}>{name} </h6>
                                    <small>{typeDescription}</small>
                                </div>
                            </Col>
                            <Col className="d-flex align-items-start justify-content-end" style={{position:'absolute', right: 15}}>
                                <Badge pill className={'badge-primary'} >
                                    <span className="text-primary-gradient" >
                                        {typeMapping[type]}
                                    </span>
                                </Badge>
                            </Col>
                        </Row>
                        <div>
                            <Row >

                                <Col className="farming-stats-group text-start">
                                    <small className="farming-stats-value-label">Chain</small>
                                    <p className="farming-stats-value-item">
                                        <img style={{ width: '22px', height: '22px' }} 
                                        src={networkIcon[networks[vaultInfo.chainId].shortName]} alt="icon" /> 
                                        <span className='ms-2 my-auto'>{networks[vaultInfo.chainId].shortName}</span>
                                    </p>
                                </Col>
                                <Col className="farming-stats-group text-end">
                                    <small className="farming-stats-value-label">APR</small>
                                    <p className="farming-stats-value-item">
                                        {vaultData && vaultData.ended ?
                                            "Vault ended"
                                            :
                                            <>
                                                <small>%</small><CountUp isCounting end={(vaultData && vaultData.return) || 0} decimals={2} separator=',' />
                                            </>
                                        }
                                    </p>
                                </Col>
                            </Row>

                            <Row >
                                <Col className="farming-stats-group text-start">
                                    <small className="farming-stats-value-label">Status </small>
                                    <p className="farming-stats-value-item">
                                        {status}
                                    </p>
                                </Col>
                    
                                <Col className="farming-stats-group text-end">
                                    {type !== 'AUTOCOMPOUND_RELOCK' ? <><small className="farming-stats-value-label">Rewards </small>
                                        <p className="farming-stats-value-item">
                                            <CountUp isCounting end={(vaultData && vaultData.totalRewardFund) || 0} decimals={0} preserveValue={true} separator=',' />
                                        </p>
                                    </> : <>
                                        <small className="farming-stats-value-label">Relock Bonus </small>
                                        <p className="farming-stats-value-item">
                                            <CountUp isCounting end={(vaultData && vaultData.relockBonus) || 0} decimals={2} separator=',' />%
                                        </p>
                                    </>}
                                </Col>
                            </Row>
                            <Row className="justify-content-between align-items-center mt-2">
                                <Col sm={12} className="mb-sm-0 mb-3">
                                    <Button onClick={
                                        () => {
                                            navigate("/vaults/" + location);
                                        }}
                                        // disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                                        className="text-white w-100" block>View Vault</Button>
                                </Col>

                            </Row>
                        </div>
                    </Card.Body>
                </Card >
            </div>
        </>
    );
}

export default Vault;
