
import React, { useEffect, useState } from 'react';

import { useCurrentVault } from '../../../state/vault-staking';
import { useVaultAddress } from '../../../utils/utils';

import VaultHeaderInfo from "../common/vault-header";
import VaultHeaderStats from "../common/vault-header-stats";

function VaultHeader({ address }) {
    const vaultAddress = useVaultAddress();
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);

    const [blocksLeft, setBlocksLeft] = useState();
    const [stakeTax, setStakeTax] = useState();
    const [unstakeTax, setUnstakeTax] = useState();


    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setBlocksLeft(vaultData.blocksLeft);
        setStakeTax(vaultData.stakeTax);
        setUnstakeTax(vaultData.unStakeTax);
    }, [vaultData]);


    return <VaultHeaderInfo address={address} Stats={
        <VaultHeaderStats
            blocksLeft={blocksLeft}
            stakeTax={stakeTax}
            unstakeTax={unstakeTax} />
    } />
}

export default VaultHeader;
