import React, { useContext } from 'react';
import { Button, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import NumberFormat from 'react-number-format';
import NetworkContext from '../../../context/network-context';


const VaultHeaderStats = ({ handleClaim, stakeTax, unstakeTax, currentReward, lockTime }) => {
    const { connected, pendingTransaction } = useContext(NetworkContext);

    return (<>
        <Col xs={12} sm={4} md={12} className="text-center text-md-end mb-3">
            <small className="vault-stats-value-label">Lock Period</small>
            <p className="vault-stats-value-item text-primary-gradient">
                <NumberFormat value={lockTime || 0} displayType={'text'} thousandSeparator={true} /> Days
            </p>
        </Col>

        {(stakeTax || unstakeTax) && <>
            <Col xs={12} sm={4} md={6} className="text-center text-md-end mb-3">
                <small className="vault-stats-value-label">Stake Tax</small>
                <p className="vault-stats-value-item text-primary-gradient">
                    <NumberFormat value={stakeTax || 0} displayType={'text'} thousandSeparator={true} />%
                </p>
            </Col>


            <Col xs={12} sm={4} md={6} className="text-center text-md-end mb-3">
                <small className="vault-stats-value-label">Unstake Tax</small>
                <p className="vault-stats-value-item text-primary-gradient">
                    <NumberFormat value={unstakeTax || 0} displayType={'text'} thousandSeparator={true} /> %
                </p>
            </Col>
        </>}

        <Col md={6} className="text-center text-md-end mb-3 p-0">
            <small className="vault-stats-value-label">Current Bounty </small>
            <p className="vault-stats-value-item text-primary-gradient mt-0">
                <CountUp isCounting end={currentReward} preserveValue={true} decimals={4} separator=',' />HPAY
            </p>
        </Col>


        <Col md={6} className="text-center text-md-end mb-3">
            <Button onClick={handleClaim} disabled={!connected || pendingTransaction}>Claim Reward</Button>
        </Col>


    </>)
}

export default VaultHeaderStats;
