import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AutoNumeric from 'autonumeric';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import BigNumber from 'bignumber.js';

import { useApprove } from '../../../web3/account';
import NetworkContext from '../../../context/network-context';

function VaultInput({ mode, balance, action, vault, userLocked, withdrawTax }) {
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { approve, isApproved } = useApprove(vault.stakeToken, account, vault.address);
    const [isValid, setIsValid] = useState(false);
    const input = useRef(null);
    const [inputHandle, setInputHandle] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const checkValid = useCallback(() => {

        const amount = inputHandle.getNumber();
        if (amount > balance) {
            setErrorMessage('Insufficient Balance');
            setIsValid(false);
            return;
        }

        if (amount + userLocked > vault.maxLockPerWallet) {
            setErrorMessage(`You can only lock ${vault.maxLockPerWallet.toLocaleString()} per round.`);
            setIsValid(false);
            return;
        }
        setErrorMessage(null);
        setIsValid(!!inputHandle.getNumber() && inputHandle.getNumber() > 0);
    }, [inputHandle, setIsValid, balance, userLocked, vault]);

    const handleApprove = useCallback(async () => {
        const tx = async () => await approve();

        await executeTransaction({
            message: 'Approving ',
            tx
        });

    }, [approve, executeTransaction]);

    useEffect(() => {
        if (input.current) {
            setInputHandle(new AutoNumeric(input.current, 0, {
                decimalPlaces: 8,
                minimumValue: 0,
                allowDecimalPadding: false,
                showWarnings: false,
                emptyInputBehavior: 0,
                decimalCharacterAlternative: ','
            }));
        }
    }, [input, setInputHandle]);

    const setInput = useCallback((value) => () => {
        const nr = new BigNumber(+((balance * value) / 100)).toFixed(6, 1);
        inputHandle.set(+nr);
        checkValid();
    }, [inputHandle, checkValid, balance]);

    const ActionButton = () => {
        if (isApproved) {
            return (<Button disabled={!connected || !isValid || !isCorrectNetwork || !!pendingTransaction}
                onClick={() => action(inputHandle.getNumber())}
                className="w-100">Deposit</Button>);
        } else {
            return (<Button disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                onClick={handleApprove}  className="w-100">Approve</Button>);
        }
    };

    const Stake = <Card>
        <Card.Body>
            <Row >
                <Col sm={12} md={6} className="text-start">
                    <h2 className="mb-2 text-white text-center text-md-start">Stake</h2>
                </Col>
            </Row>

            <Row className="justify-content-center no-gutters">
                <Col md={12}>
                    <Form>
                        <Form.Group className="staking-control" controlId="validationFormik01">
                            <div className="d-flex justify-content-between">
                                <Form.Label className="balance-label">Amount</Form.Label>
                                <Form.Label className="text-end balance-label">
                                    Balance <NumberFormat decimalScale={6} value={+balance.toFixed(4)} displayType={'text'} thousandSeparator={true} />
                                </Form.Label>
                            </div>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    className="staking-input mb-2"
                                    type="text"
                                    autoComplete="off"
                                    inputMode="decimal"
                                    name="baseInput"
                                    ref={input}
                                    onChange={checkValid}
                                />
                                <InputGroup.Text className="staking-input-append">
                                    {vault.ticker}
                                </InputGroup.Text>
                            </InputGroup>
                            <div className="flex justify-content-between">
                                <span role="button" tabIndex="0" onKeyDown={setInput(25)} onClick={setInput(25)} className="clickable badge badge-pill badge-primary-gradient">25%</span>
                                <span role="button" tabIndex="0" onKeyDown={setInput(50)} onClick={setInput(50)} className="clickable badge badge-pill badge-primary-gradient">50%</span>
                                <span role="button" tabIndex="0" onKeyDown={setInput(75)} onClick={setInput(75)} className="clickable badge badge-pill badge-primary-gradient">75%</span>
                                <span role="button" tabIndex="0" onKeyDown={setInput(100)} onClick={setInput(100)} className="clickable badge badge-pill badge-primary-gradient">100%</span>
                            </div>

                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mt-4">
                            <ActionButton />
                            {connected && isCorrectNetwork && errorMessage && <p className="mt-2 text-center">{errorMessage}</p>}
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Card.Body>
    </Card >;

    const Unstake = <Card>
        <Card.Body>
            <Row style={{ marginBottom: '2.5rem' }}>
                <Col sm={12} md={6} className=" text-md-start text-center   mb-3 ">
                    <h2 className="mb-0 text-white text-center text-md-start">Unstake</h2>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Form>
                        <Row className="justify-content-center no-gutters">

                            <h6 className='text-center text-primary-gradient'>A {withdrawTax}% fee will be deducted for early unlocking.</h6>
                            <Form.Group as={Col} md="12" className="mt-4">
                                <Button disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                                    onClick={() => action()}
                                    block>Unlock</Button>
                                {connected && isCorrectNetwork && errorMessage && <p className="mt-2 text-center">{errorMessage}</p>}
                            </Form.Group>
                        </Row>
                    </Form>
                </Col>

            </Row>
        </Card.Body>
    </Card >;

    return (
        <div className="staking-input-component">
            {mode === 'stake' ? Stake : Unstake}
        </div >
    );
}

export default VaultInput;
