import React from 'react';
import { useParams } from '@reach/router';
import { Col, Row } from "react-bootstrap";

import VaultHeader from "./vault-header";
import VaultStats from "./vault-stats";
import VaultInfo from "./vault-info";


function Vault() {
    const params = useParams();
    return (
        <Row className="mb-2 ">
            <Col md={12} className="mb-md-5 mb-4">
                <VaultHeader address={params.vaultAddress}></VaultHeader>
            </Col>

            <Col lg={6} xl={6} >
                <VaultStats address={params.vaultAddress} ></VaultStats>
            </Col>

            <Col lg={6} xl={6} >
                <VaultInfo address={params.vaultAddress} ></VaultInfo>
            </Col>
        </Row>
    );
}

export default Vault;
