import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Vaults from "../containers/vault-list";
import Vault from "../containers/vault";

import { Router } from "@reach/router";
const useIsClient = () => {
	const [isClient, setClient] = useState(false);
	const key = isClient ? "client" : "server";

	useEffect(() => {
		setClient(true);
	}, []);

	return { isClient, key };
};

const IndexPage = () => {
	const { isClient } = useIsClient();

	return (
		<Layout pageInfo={{ pageName: "rewards" }}>
			<ScrollUpButton ContainerClassName="custom-scroll-button" />
			<Seo title="Rewards" />

			<Row >
				<Col>
					<Container className="mt-5">
						<section id="vaults">
							{isClient && <Router basepath="/vaults">
								<Vaults path="/"/>
								<Vault path="/:vaultAddress"/>
							</Router>}
						</section>
					</Container>
				</Col>
			</Row>


		</Layout>
	);
};

export default IndexPage;
