import React, { useEffect, useState } from 'react';
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import NetworkSetter from '../components/network/networksetter';
import VaultPreview from '../components/vaults/common/vault-preview';
import { useVaults } from '../state/vault-staking';
import { Paginated } from './paginated';

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};


const VaultList = ({ vaults }) => {
    return <Row className='w-100 mx-auto'>
        {
            vaults && vaults.map((vault, index) => (
                <Col key={index} lg={6} xl={4} className="mb-4 vault">
                    <VaultPreview {...vault}></VaultPreview>
                </Col>)
            )
        }
    </Row>;
};

function Vaults() {
    const { isClient } = useIsClient();
    const { vaults, paginate, onPageClick, onSearchByName } = useVaults();

    if (!isClient) { return null; }
    return (
        <>
            <NetworkSetter chainId={'any'}></NetworkSetter>
            <Row className="mb-2 ">
                <Col md={12} className="mb-md-5 mb-4">
                    <div className="reward-claim-component">
                        <Row className="mb-lg-3 mb-2 reward-header justify-content-center text-center">
                            <Col md={7} lg={7}>
                                <h2 className="title-seperator--centered">HedgePay Vaults</h2>
                                <p className='m-0'>
                                    HedgePay offers off the shelf staking as a service for any ERC20 compliant token.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={8} md={6} sm={12} className="mb-md-5 mb-4 mx-auto">
                    <InputGroup className='form-hpay-input-group'>
                        <FormControl placeholder="Search here..." onChange={onSearchByName} className='form-hpay' />
                    </InputGroup>
                </Col>
                <Col md={12} className="mb-md-5 mb-4 d-flex justify-content-center flex-column align-items-center">
                    <Row className='w-100'>
                        <VaultList vaults={vaults} />
                    </Row>
                </Col>
                <Col md={12} className="mb-md-5 mb-4 d-flex justify-content-center flex-column align-items-center">
                    <Paginated forcePage={paginate.page} nextLabel=">" onPageChange={({ selected }) => onPageClick({ page: selected + 1 })} pageCount={paginate.pageCount} previousLabel="<" renderOnZeroPageCount={null} />
                </Col>
            </Row>
        </>
    );
}



export default Vaults;
