import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import VaultsData from "hpay/content/Vaults.json";

import FlashStakingVault from "../components/vaults/flash-staking/vault";
import AutoCompundRelock from "../components/vaults/autocompound-relock/vault";

import VaultConatiner from "../components/vaults/common/vault";
import simpleStaking from '../components/vaults/simple-staking';
import timeLock from '../components/vaults/time-lock';
import NetworkSetter  from '../components/network/networksetter';


const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);
    return { isClient, key };
};

function Vault() {
    const params = useParams();
    const [vault, setVault] = useState();
    const { isClient } = useIsClient();

    useEffect(() => {
        const _vault = VaultsData[params.vaultAddress]
        setVault(_vault);
    }, [params]);

    if (!isClient) { return null; }

    return <>
        <NetworkSetter chainId={vault && vault.chainId}></NetworkSetter>
        {
            vault && vault.type === 'FLASH_STAKING' && <FlashStakingVault></FlashStakingVault>
        }
        {
            vault && vault.type === 'TIME_LOCK' && <VaultConatiner {...timeLock}></VaultConatiner>
        }
        {
            vault && vault.type === 'SIMPLE_STAKING' && <VaultConatiner {...simpleStaking}></VaultConatiner>
        }
        {
            vault && vault.type === 'AUTOCOMPOUND_RELOCK' && <AutoCompundRelock></AutoCompundRelock>
        }
    </>;
}

export default Vault;
