
import React, { useContext } from 'react';
import NetworkContext from '../../../context/network-context';
import StakingControls from "../common/staking-controls";

import { useCurrentBountyReward } from '../../../state/autocompound-relock';
import { useVaultAddress } from '../../../utils/utils';
import { useVaultActions } from '../../../web3/vault-staking';
import { useVaultActions as useACRVaultActions } from '../../../web3/autocompound-relock';
import { useCurrentVault, useUserIsWhitelisted, useUserVaultAmounts } from '../../../state/vault-staking';


const useControls = (address) => {
    const vaultAddress = useVaultAddress();
    const { account } = useContext(NetworkContext);
    const { isWhitelisted } = useUserIsWhitelisted(vaultAddress);
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);

    const { lock, unlock, claim, compound } = useVaultActions(account, vaultAddress);
    const { relock } = useACRVaultActions(account, vaultAddress);

    const { userLocked, userEarned, userUnlockTime, isUnlocked, userEarnedReflection } = useUserVaultAmounts(vaultAddress);
    const { bountyReward } = useCurrentBountyReward(vaultAddress);

    return {
        address, bountyReward, relock,
        isWhitelisted, vaultData, lock, unlock, claim,
        compound, userLocked, userEarned, userUnlockTime,
        isUnlocked, userEarnedReflection
    };
}

function VaultStats({ address }) {
    const controls = useControls(address);
    return <StakingControls {...controls}></StakingControls>;
}

export default VaultStats;
