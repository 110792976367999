import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import NetworkContext from '../../../../context/network-context';
import { fetchTokenBalance } from '../../../../web3/account';
import { useVaultAdminActions } from '../../../../web3/vault-staking';
import FundingInput from './funding-input';
import Whitelist from '../../../whitelist-manager/whitelist-manager';

function AdminPanel({ address, ...vault }) {
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const [openForAll, setOpenForAll] = useState();

    const { setOpenForAll: toggleOpen, topUpVaultRewards, startVault } = useVaultAdminActions(address, account);
    const [showFundingModal, setShowFundingModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const handleClose = () => {
        if (!pendingTransaction) {
            setShowFundingModal(false);
        }
    };

    useEffect(() => {
        if (!vault) {
            return;
        }
        if (vault.whitelisted) {
            setOpenForAll(vault.openForAll);
        }
    }, [vault]);


    const toggleOpenForAll = async () => {
        const tx = async () => toggleOpen(!openForAll);

        await executeTransaction({
            message: 'Processing',
            tx
        });
        setOpenForAll(!openForAll);
    };

    const handleStart = useCallback(async () => {
        if (!vault) {
            return;
        }
        try {

            await executeTransaction({
                message: 'Starting Vault',
                tx: startVault
            });

        } catch (error) {
            console.log(error);
        }

    }, [vault, startVault, executeTransaction]);

    const handleFunding = useCallback(async () => {
        if (!vault) {
            return;
        }

        const balance = await fetchTokenBalance(vault.stakeToken, account, vault.chainId);
        setModalStatus({
            balance,
            action: async (amount) => {
                const tx = async () => topUpVaultRewards(amount);
                try {

                    await executeTransaction({
                        message: 'Sending Funds',
                        tx
                    });

                } catch (error) {
                    console.log(error);
                }

                setShowFundingModal(false);
            }
        });

        setShowFundingModal(true);
    }, [setModalStatus, setShowFundingModal, vault, account, executeTransaction, topUpVaultRewards]);


    return (<>
        <Row>
            <Col md={6}>
                <Col md={12}>
                    <h1>Vault Manager</h1>
                    <p className="banner-info mt-4">
                        Please ensure that the vault address <strong style={{ wordBreak: 'break-word' }} className='text-primary-gradient '> {address} </strong>  is excluded from any transaction mechanics such as fees, rewards or reflections and that it can freely accept the reward supply.
                    </p>
                </Col>
                {vault && vault.whitelisted &&
                    <Col md={12}>
                        <h3>Restrictions</h3>
                        <p>{openForAll ? "Open For All" : "Only whitelisted addresses can stake"}</p>
                        <Button onClick={toggleOpenForAll} disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                            className="btn text-white">{openForAll ? "Restrict" : "Open For All"}</Button>


                        <Whitelist address={vault.address}></Whitelist>
                    </Col>
                }

            </Col>

            <Col md={6}>
                <Col md={12}>
                    <h3 className='mt-5'>Funding Status</h3>
                    <p className='mb-2'>Top up your vault with rewards and hit start to let your users stake!</p>
                </Col>
                {vault && !vault.started ?
                    <div className='d-flex'>
                        <Col md={6}>
                            <Button onClick={handleFunding} 
                                disabled={!connected || !isCorrectNetwork || !!pendingTransaction} className="btn w-100 text-white">Top Up</Button>
                        </Col>
                        <Col md={6}>
                            <Button onClick={handleStart} 
                                disabled={!connected || !isCorrectNetwork || !!pendingTransaction || vault.totalRewardFund === 0} className="btn w-100 text-white">Start</Button>
                        </Col>
                    </div> : "Vault already started"
                }
            </Col>
        </Row >

        <Modal
            className="stake-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={!!showFundingModal} onHide={handleClose} >

            <Modal.Body>
                <FundingInput vault={vault} address={address} {...modalStatus}></FundingInput>
            </Modal.Body>

        </Modal>
    </>);
}

export default AdminPanel;
