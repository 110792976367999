
import React, { useContext } from 'react';
import NetworkContext from '../../../context/network-context';
import StakingControls from "../common/staking-controls";

import { useCurrentVault, useUserIsWhitelisted, useUserVaultAmounts } from '../../../state/vault-staking';
import { useVaultAddress } from '../../../utils/utils';
import { useVaultActions } from '../../../web3/vault-staking';

const useControls = (address) => {
    const vaultAddress = useVaultAddress();
    const { account } = useContext(NetworkContext);
    const { isWhitelisted } = useUserIsWhitelisted(vaultAddress);
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const { lock, unlock, claim, compound } = useVaultActions(account, vaultAddress);
    const { userLocked, userEarned, userUnlockTime, isUnlocked, userEarnedReflection } = useUserVaultAmounts(vaultAddress);
    return { address, isWhitelisted, vaultData, lock, unlock, claim, compound, userLocked, userEarned, userUnlockTime, isUnlocked, userEarnedReflection };
}

function VaultStats({ address }) {
    const controls = useControls(address);
    return <StakingControls {...controls}></StakingControls>;
}

export default VaultStats;
