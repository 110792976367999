
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useInterval } from "react-use";
import NetworkContext from '../../../context/network-context';
import { useCurrentBountyReward, useRefreshCurrentReward } from '../../../state/autocompound-relock';
import { useCurrentVault } from '../../../state/vault-staking';
import { useVaultAddress } from '../../../utils/utils';
import { useCompoundRewardActions } from '../../../web3/autocompound-relock';
import VaultHeaderInfo from "../common/vault-header";
import VaultHeaderStats from "./vault-header-stats";


function VaultHeader({ address }) {
    const vaultAddress = useVaultAddress();
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const [, refreshReward] = useRefreshCurrentReward(vaultAddress);
    const { currentReward } = useCurrentBountyReward(vaultAddress);

    const { account, executeTransaction } = useContext(NetworkContext);

    const [stakeTax, setStakeTax] = useState();
    const [unstakeTax, setUnstakeTax] = useState();
    const [lockTime, setLockTime] = useState();

    useInterval(refreshReward, 10000);
    
    const { runCompund } = useCompoundRewardActions(vaultAddress, account);
    const handleClaim = useCallback(async () => {
        const tx = async () => {
            return await runCompund(10);
        };
        try {
            await executeTransaction({
                message: 'Claming',
                tx
            });

        } catch (error) {
            console.log(error);
        }
    }, [runCompund, executeTransaction]);


    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setStakeTax(vaultData.stakeTax);
        setUnstakeTax(vaultData.unStakeTax);
        setLockTime(vaultData.lockTime);
    }, [vaultData]);


    return (<VaultHeaderInfo address={address} Stats={
        <VaultHeaderStats handleClaim={handleClaim}
            currentReward={currentReward}
            stakeTax={stakeTax}
            lockTime={lockTime}
            unstakeTax={unstakeTax} />
    } />);
}

export default VaultHeader;
