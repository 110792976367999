import React from 'react';
import { Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';


const VaultHeaderStats = ({ blocksLeft, lockTime, stakeTax, unstakeTax }) => {
    return (<>
        <Col xs={12} sm={4} md={6} className="text-center text-md-end">
            <small className="vault-stats-value-label">Blocks Left</small>
            <p className="vault-stats-value-item text-primary-gradient">
                <NumberFormat value={blocksLeft} displayType={'text'} thousandSeparator={true} />
            </p>
        </Col>

        {lockTime && <Col xs={12} sm={4} md={6} className="text-center text-md-end">
            <small className="vault-stats-value-label">Lock Time</small>
            <p className="vault-stats-value-item text-primary-gradient">
                {lockTime} Days
            </p>
        </Col>}

        {stakeTax > 0 && <Col xs={12} sm={4} md={6} className="text-center text-md-end">
            <small className="vault-stats-value-label">Stake Tax</small>
            <p className="vault-stats-value-item text-primary-gradient">
                <NumberFormat value={stakeTax} displayType={'text'} thousandSeparator={true} />%
            </p>
        </Col>}


        {unstakeTax > 0 && <Col xs={12} sm={4} md={6} className="text-center text-md-end">
            <small className="vault-stats-value-label">Unstake Tax</small>
            <p className="vault-stats-value-item text-primary-gradient">
                <NumberFormat value={unstakeTax} displayType={'text'} thousandSeparator={true} /> %
            </p>
        </Col>
        }
    </>)
}

export default VaultHeaderStats;
