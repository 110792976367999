import AutoNumeric from 'autonumeric';
import BigNumber from 'bignumber.js';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import NetworkContext from '../../../../context/network-context';
import { useApprove } from '../../../../web3/account';

function FundingInput({ balance, action, vault, address }) {
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { approve, isApproved } = useApprove(vault.stakeToken, account, address, vault.chainId);
    const [isValid, setIsValid] = useState(false);
    const input = useRef(null);
    const [inputHandle, setInputHandle] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const checkValid = useCallback(() => {
        const amount = inputHandle.getNumber();
        if (amount > balance) {
            setErrorMessage('Insufficient Balance');
            setIsValid(false);
            return;
        }

        setErrorMessage(null);
        setIsValid(!!inputHandle.getNumber() && inputHandle.getNumber() > 0);
    }, [inputHandle, setIsValid, balance]);

    const handleApprove = useCallback(async () => {
        const tx = async () => await approve();

        await executeTransaction({
            message: 'Approving ',
            tx
        });

    }, [approve, executeTransaction]);

    useEffect(() => {
        setInputHandle(new AutoNumeric(input.current, 0, {
            decimalPlaces: 8,
            minimumValue: 0,
            allowDecimalPadding: false,
            showWarnings : false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        }));

    }, [input, setInputHandle]);

    const setInput = useCallback((value) => () => {
        const nr = new BigNumber(+((balance * value) / 100)).toFixed(6, 1);
        inputHandle.set(+nr);
        checkValid();
    }, [inputHandle, checkValid, balance]);

    const ActionButton = () => {
        if (isApproved) {
            return (<Button disabled={!connected || !isValid || !isCorrectNetwork || !!pendingTransaction}
                onClick={() => {
                    const amount = new BigNumber(inputHandle.getNumber()).toFixed(6, 1);
                    action(amount);
                }}
                block>Liquidity</Button>);
        } else {
            return (<Button disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                onClick={handleApprove} block>Approve</Button>);
        }
    };
    return (
        <div className="staking-input-component">
            <Card>
                <Card.Body>

                        <h2 className="mb-4 text-white text-center text-md-start">Top Up Vault</h2>



                    <Col md={12}>
                        <Form>
                            <Row className="justify-content-center">
                                <Form.Group as={Col} md="12" className="input-control" controlId="validationFormik01">
                                    <div className="d-flex justify-content-between">
                                        <Form.Label className="balance-label">Amount</Form.Label>
                                        <Form.Label className="text-end balance-label">
                                            Balance <NumberFormat decimalScale={6} value={+balance.toFixed(4)} displayType={'text'} thousandSeparator={true} />
                                        </Form.Label>
                                    </div>
                                    <InputGroup >
                                        <Form.Control
                                            className="staking-input mb-2"
                                            type="text"
                                            autoComplete="off"
                                            inputMode="decimal"
                                            name="baseInput"
                                            ref={input}
                                            onChange={checkValid}
                                        />
                                        <InputGroup.Text className="staking-input-append">
                                            {vault.ticker}
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <div className="flex justify-content-between">
                                        <span role="button" tabIndex="0" onKeyDown={setInput(25)} onClick={setInput(25)} className="clickable badge badge-pill badge-primary-gradient">25%</span>
                                        <span role="button" tabIndex="0" onKeyDown={setInput(50)} onClick={setInput(50)} className="clickable badge badge-pill badge-primary-gradient">50%</span>
                                        <span role="button" tabIndex="0" onKeyDown={setInput(75)} onClick={setInput(75)} className="clickable badge badge-pill badge-primary-gradient">75%</span>
                                        <span role="button" tabIndex="0" onKeyDown={setInput(100)} onClick={setInput(100)} className="clickable badge badge-pill badge-primary-gradient">100%</span>
                                    </div>

                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mt-4 p-0">
                                    <ActionButton />
                                    {connected && isCorrectNetwork && errorMessage && <p className="mt-2 text-center">{errorMessage}</p>}

                                </Form.Group>
                            </Row>
                        </Form>
                    </Col>


                </Card.Body>
            </Card >
        </div >
    );
}

export default FundingInput;
