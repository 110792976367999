
import VaultsData from "hpay/content/Vaults.json";
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import CountUp from 'react-countup';
import { useCurrentVault, useRefreshCurrentVault, useUserVaultAmounts } from '../../../state/vault-staking';
import { useVaultAddress } from '../../../utils/utils';

function VaultInfo({ address }) {
    const vaultAddress = useVaultAddress();
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const { userLocked, userUnlockTime, isUnlocked } = useUserVaultAmounts(vaultAddress);
    const [vault, setVault] = useState(null);
    const [, refreshVault] = useRefreshCurrentVault(address);

    // const [status, setStatus] = useState(statusMapping[0]);
    // useEffect(() => {
    //     if (!vaultData) {
    //         return;
    //     }
    //     setStatus(statusMapping[vaultData.status]);
    // }, [vaultData]);

    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);



    const headerText = <small>
        {
            !vaultData && <>
                Loading...
            </>
        }
        {
            vaultData && vaultData.status === 0 && <>
                <span className="text-primary-gradient">{vault && vault.ticker}</span> time lock staking will start soon.
            </>
        }

        {
            vaultData && vaultData.status === 1 && <>
                Lock your <span className="text-primary-gradient">{vault && vault.ticker}</span> to earn.
            </>
        }

        {
            vaultData && vaultData.status === 2 && <>
                All your staked <span className="text-primary-gradient">{vault && vault.ticker}</span> is locked.
            </>
        }
    </small>;

    return (
        <div className="reward-claim-component h-100 ">
            <Card>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex  justify-content-between  " >

                        <div className='farming-stats-group'>
                            <p className="farming-stats-value-item mb-0">Staking Info</p>
                            {
                                headerText
                            }

                        </div>
                        <div >

                            <Badge pill  >
                                <span className="text-primary-gradient">
                                    Running
                                </span>
                            </Badge>
                        </div>

                    </div>

                    <div className="w-100">
                        <Row >

                            <Col md={6} className="farming-stats-group text-md-start text-end ">
                                <small className="farming-stats-value-label">You Locked  </small>
                                <p className="farming-stats-value-item mb-0">

                                    <CountUp isCounting end={userLocked || 0} decimals={2} separator=',' /> {vault && vault.ticker}
                                </p>
                            </Col>
                            <Col className="farming-stats-group text-end">
                                <small className="farming-stats-value-label">APY </small>
                                <p className="farming-stats-value-item">
                                    <CountUp isCounting end={(vaultData && vaultData.apy) || 0} decimals={0} separator=',' />%
                                </p>
                            </Col>


                        </Row>

                        <Row >
                            <Col className="farming-stats-group text-start mb-0 ">
                                <small className="farming-stats-value-label">Your Lock Ends In </small>
                                <p className="farming-stats-value-item text-primary-gradient">
                                    {!isUnlocked ? <>
                                        {
                                            !!!userLocked ? "Nothing to unlock" : <Countdown onComplete={() => {
                                                setTimeout(() => {
                                                    refreshVault();
                                                }, 5000);
                                            }} key={userUnlockTime} date={userUnlockTime}></Countdown>
                                        }  </> : <> {
                                            !!!userLocked ? "Nothing to unlock" : "Your locking period has ended"
                                        }
                                    </>
                                    }
                                </p>
                            </Col>
                            <Col className="farming-stats-group text-start  mb-0 text-end ">
                                <small className="farming-stats-value-label"> Early Unlock Penalty </small>
                                <p className="farming-stats-value-item">
                                    {vaultData && vaultData.penalty > 0 && <CountUp isCounting end={(vaultData && vaultData.penalty) || 0} decimals={2} separator=',' /> + "+ %"} 100% of earnings
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Card.Body >
            </Card >
        </div >
    );
}

export default VaultInfo;
