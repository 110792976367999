
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import VaultsData from "hpay/content/Vaults.json";

import Telegram from '../../../images/inline/telegram-icon.inline.svg';
import Twitter from '../../../images/inline/twitter.inline.svg';
import { useCurrentVault } from '../../../state/flash-staking';
import { useVaultAddress } from '../../../utils/utils';
import { Link } from 'gatsby';

function VaultHeader({ address }) {
    const vaultAddress = useVaultAddress(address);

    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const [vault, setVault] = useState(null);
    const [maxLock, setMaxLock] = useState(null);
    const [maxWallets, setMaxWallets] = useState(null);
    const [maxLockPerWallet, setMaxLockPerWallet] = useState(null);
    const [apr, setApr] = useState(null);

    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);

    useEffect(() => {
        if (!vaultData) {
            return;
        }

        setMaxLock(numeral(vaultData.maxLock).format('0,0a'));
        setApr(numeral(vaultData.return).format('0,0'));
        setMaxLockPerWallet(numeral(vaultData.maxLockPerWallet).format('0,0a'));
        setMaxWallets(vaultData.maxLock / vaultData.maxLockPerWallet);

    }, [vaultData]);


    const SocialMedia = <div className='ms-auto align-self-start'>
        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-telegram`}>
                    <strong>Telegram</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.telegram}>
                <Telegram className="footer-icon" />
            </a>
        </OverlayTrigger>

        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-twitter`}>
                    <strong>Twitter</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.twitter}>

                <Twitter className="footer-icon" />
            </a>
        </OverlayTrigger>
    </div>;

    return (
        <div className="reward-claim-component">
            {/* <Link
                href="/dex"
                className="section-title text-center btn btn-primary mb-5 w-100"
                style={{ borderRadius: "15px" }}
            >
                <div>On Nov 3, Buy HPAY at 0% tax</div>
                <h3>On Nov 3, Buy HPAY at 0% tax</h3>
            </Link> */}
            <Row className="mb-lg-5 mb-4 reward-header">
                <Col md={8} lg={9}>

                    <div className="d-flex align-items-center">
                        <img
                            style={{ borderRadius: '50%', alignSelf: 'baseline' }}
                            src={'/vaults/' + (vault && vault.imagePath)}
                            alt="flash"
                            width={60}
                            height={60}
                        />
                        <h2 className="title-seperator ms-2">{vault && vault.owner} Flash Staking</h2>
                        {SocialMedia}
                    </div>
                    <Col md={12}>
                        <p className='text-justify '>
                            Staking with a few twists! Holders will have the opportunity to lock up
                            to  <span className='text-primary-gradient font-weight-bold'>
                                {maxLockPerWallet}
                            </span> of

                            their tokens, for one round
                            at <span className='text-primary-gradient font-weight-bold'>
                                {apr}%
                            </span> FIXED APR!

                            There will be a maximum

                            of <span className='text-primary-gradient font-weight-bold'>
                                {maxLock}
                            </span> tokens

                            that can be staked before the pool closes. That means if all the holders who are able to get into the staking pool stake the maximum allotment

                            of <span className='text-primary-gradient font-weight-bold'>{maxLockPerWallet}</span>  tokens,

                            only <span className='text-primary-gradient font-weight-bold'>{maxWallets && (maxWallets - 0.5).toFixed()} holders</span> will
                            get in per round! Once the rounds ends, all tokens in the pool will be unlocked, rewards can be claimed and
                            the  <span className='text-primary-gradient font-weight-bold'>
                                staking window </span> will
                            be opened again! So, holders who wish to participate must have quick fingers if they want their share of these juicy returns. Let the games begin!
                        </p>
                    </Col>
                </Col>

                <Col md={4} lg={3} className=" d-flex align-items-center  text-center bounty-widget flex-wrap m-3 m-md-0">
                    <Col xs={12} sm={4} md={6} className="text-center text-md-start ">
                        <small className="farming-stats-value-label">Round</small>
                        <p className="farming-stats-value-item text-primary-gradient">
                            {vaultData && vaultData.round} /  {vaultData && vaultData.totalRounds}
                        </p>
                    </Col>

                    <Col xs={12} sm={4} md={6} className="text-center text-md-end">
                        <small className="farming-stats-value-label">Round Time</small>
                        <p className="farming-stats-value-item text-primary-gradient">
                            {vaultData && vaultData.roundTime} Days

                        </p>
                    </Col>

                    <Col xs={12} sm={4} md={12} className="text-center text-md-end">
                        <small className="farming-stats-value-label">Stake Window</small>
                        <p className="farming-stats-value-item text-primary-gradient">
                            {vaultData && vaultData.stakeWindow} Hours
                        </p>
                    </Col>

                </Col>
            </Row>
        </div>
    );
}

export default VaultHeader;
